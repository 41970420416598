import { AxiosError } from 'axios';

export enum restError {
  MISSING_CALLBACK_PARAM = 'rest_missing_callback_param',
  LOGIN_FAILED = 'rest_login_failed',
  INVALID_PARAM = 'rest_invalid_param',
  FORBIDDEN = 'rest_forbidden',
  INVALID_JSON = 'rest_invalid_json',
  NO_ROUTE = 'rest_no_route',
  // NO_DATA = 'rest_no_data',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
}

export enum level {
  error = 'Error',
  warning = 'Warning',
  log = 'Log',
}

type customError = Partial<AxiosError> & { code:string, message: string; level?: level.error | level.warning | level.log };
export class ClassErrorHandler {
  public code = '';
  private errorsUsefullForUser = [restError.MISSING_CALLBACK_PARAM, restError.LOGIN_FAILED, restError.INVALID_PARAM];
  private genericErrorMessage = "L'opération a echoué";
  private messageError = '';
  private isAxiosError = false;
  private backEndMessageError = '';
  private error: customError;
  private _httpStatus = 0;

  constructor(error: customError | any) {
    this.error = error;
    if (this.error.response && typeof this.error.response === 'object' && 'data' in this.error.response) {
      this.code = (this.error.response as any).data.code ?? '';
    }

    this.isAxiosError = error.isAxiosError || false;

    if (this.isErrorFromBackEnd()) {
      this.setBackEndMessageError();
    }

    if (error.message) {
      this.messageError = error.message;
    }
  }

  public displayErrorIfDev() {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    if (this.error.level === level.log) {
      return;
    }

    this.popStandardErrorToast();
    this.display_in_console();
  }

  public get httpStatus() {
    return this._httpStatus;
  }

  private setBackEndMessageError() {
    // const restCodeError: restError = this.error.response?.data.code;

    // switch (restCodeError) {
    //   case restError.MISSING_CALLBACK_PARAM:
    //     this.backEndMessageError = this.error.response?.data.message;
    //     break;
    //   case restError.LOGIN_FAILED:
    //     this.backEndMessageError = "La combinaison email / mot de passe n'est pas valide";
    //     break;
    //   case restError.INVALID_PARAM:
    //     this.backEndMessageError = Object.values(this.error.response?.data.data.params).join('\r\n');
    //     break;
    //   case restError.NO_ROUTE:
    //     this.backEndMessageError = this.error.response?.data.message;
    //     break;
    //   case restError.FORBIDDEN:
    //     this.backEndMessageError = this.error.response?.data.message;
    //     break;
    //   case restError.INVALID_JSON:
    //     this.backEndMessageError = this.error.response?.data.message;
    //     break;
    //   case restError.INTERNAL_SERVER_ERROR:
    //     this.backEndMessageError = "Le serveur a rencontré un problème lors du traitement de votre requête";
    //     break;
    //   // fallback for other backends
    //   default:
    //     this.backEndMessageError = this.error.response?.data.message || this.genericErrorMessage;
    //     break;
    // }

    if (this.isEnvironmentProduction() && this.isMessageTooTechnical(restCodeError)) {
      this.overrideMessageWithDefault();
    }
  }

  private isEnvironmentProduction(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  private isErrorFromBackEnd(): boolean {
    return !!this.error.response?.data?.message;
  }

  private isMessageTooTechnical(restCode: restError): boolean {
    return !this.errorsUsefullForUser.includes(restCode);
  }

  private overrideMessageWithDefault() {
    this.backEndMessageError = this.genericErrorMessage;
  }

  public display_in_console() {
    console.group(
      '%c' + (this.error.level || 'Error'),
      'background-color: ' +
      (this.error.level === level.warning ? '#ff7f00' : '#e0005a') +
      '; color: #ffffff ; font-weight: bold ; padding: 4px ;'
    );
    const source = this.isAxiosError ? 'Axios' : 'Error';

    console.log('%cMessage from ' + source, 'text-decoration: underline ; font-weight: bold ;');
    console.log(this.messageError);
    if (this.isAxiosError) {
      const url = this.error.config?.method + ' ' + this.error.config?.url;

      console.log('Request was: ' + url);
      if (this.isThereAnyParams(this.error.config)) {
        console.log('With following parameters');
        for (const [param, value] of Object.entries(this.error.config?.params)) {
          console.log(`${param} = ${value}`);
        }
      }
    }
    if (this.backEndMessageError) {
      console.log('%cMessage from Backend:', 'text-decoration: underline ; font-weight: bold ;');
      console.log(this.backEndMessageError);
      console.log('%cRaw Backend data:', 'text-decoration: underline ; font-weight: bold ;');
      console.log(this.error.response?.data);
    }
    console.log('%cRaw error:', 'text-decoration: underline ; font-weight: bold ;');
    console.log(this.error);
    console.groupEnd();
  }

  private isThereAnyParams(config: any) {
    if (config.params === undefined) {
      return false;
    }

    if (!Object.keys(this.error?.config?.params).length) {
      return false;
    }

    return true;
  }

  public popStandardErrorToast(): void {
    const toastStore = useToastStore();
    if (this.error.level === level.warning) {
      toastStore.setToastMessage(this.getStandardErrorMessage(),'success')
    } else {
      toastStore.setToastMessage(this.getStandardErrorMessage(),'error')
    }
  }

  public popCustomToast(customMessage: string): void {
    const toastStore = useToastStore();
    toastStore.setToastMessage(this.getStandardErrorMessage(),'error')
  }

  public popGenericToast(): void {
    const toastStore = useToastStore();
    toastStore.setToastMessage(this.getStandardErrorMessage(),'error')
  }

  public getStandardErrorMessage(): string {
    return this.backEndMessageError || this.messageError || this.genericErrorMessage;
  }

  public getMessageBrut(): string {
    return this.error.response?.data?.message ?? '';
  }

  public setMessageError(message: string) {
    this.messageError = message;
  }
}
